import React from 'react';

import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { createTheme, CssBaseline } from "@mui/material"
import MapView from "./views/MapView"


function App() {
  return (
      <ThemeProvider theme={ createTheme( {
        components: {
          MuiToggleButton: {
            styleOverrides: {
              root: {
                textTransform: "none",
              },
            },
          },
        },
      } ) }>
        <CssBaseline/>
          <MapView />
      </ThemeProvider>
  );
}

export default App;
