import React, { useEffect, useState } from "react"
import { MapContainer, Polyline, TileLayer } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import axios from "axios"
//@ts-ignore
import polyUtil from "polyline-encoded"
import { ColorUtil } from "./../ColorUtil"

const defaultPosition: number[] = [ 49.7438, 15.3386 ]

type ResponseLines = {
	activities: LineProp[]
	meta: {
		limit: number
		total: number
	}
}

type LineProp = {
	id: number
	data: string
}

export default function MapView() {
	const [ keys, setKeys ] = useState( [ "450de631074b",  "c62c52f9c312", "b0ab1d581301" ] )
	const [ activitiesList, setActivitiesList ] = useState<Map<string, LineProp[]>>( new Map() )


	useEffect( () => {

		function loadData(key:string, page:number) {
			axios.get<ResponseLines>( `https://kazlik.cz/sh-api.php?key=${ key }&page=${page}` ).then( ( { data } ) => {
				const list = activitiesList
				if ( !list.has( key ) ) {
					list.set( key, [] )
				}
				const listItem = list.get( key ) as LineProp[]
				listItem.push( ...data.activities )
				setActivitiesList( new Map( list ) )
				const maxPage = Math.ceil(data.meta.total / data.meta.limit)
				console.log(key)
				console.log(data.meta)
				console.log(maxPage)
				console.log(page)

				if(maxPage > page) {
					void loadData(key, page + 1)
				}
			} )
		}

		setActivitiesList(new Map())
		keys.map( key => {
			loadData(key, 1)
		} )
	}, [] )

	return (
		<MapContainer
			style={ { width: "100vw", height: "100vh" } }
			center={ [ defaultPosition[ 0 ], defaultPosition[ 1 ] ] }
			zoom={ 8 }
		>
			<TileLayer
				attribution='&copy; <a href="https://o.seznam.cz/">Seznam.cz, a.s.</a> a další'
				url="https://mapserver.mapy.cz/turist-m/{z}-{x}-{y}?s=0.0&dm=Luminosity"
			/>
			{ keys.map( ( key, i ) => {
					const activities = activitiesList.get( key )
					if ( activities ) {
						return activities.map( line => {
							return (
								<Polyline
									key={ line.id }
									positions={ polyUtil.decode( line.data ) }
									color={i === 0 ? "#ff9812" : i === 1 ? "#ff00c8" : "#0800ff"}
									opacity={0.7}
									smoothFactor={0.000001}
								/>
							)
						} )
					} else {
						return null
					}
				},
			) }
		</MapContainer>
	)
}
